import { createTheme, Theme } from "@mui/material/styles"
import * as tokens from "@theartofeducation/aoeu-design-tokens"

export const aoeuPalette = {
  aoeuBlue: "#00AFD7",
  aoeuBlue2: "#007DB6",
  darkBlue: "#0076A8",
  darkGray: "#545555",
  rioGrande: "#C4D600",
  appleGreen: "#84BD00",
  observatory: "#00966C",
  tiffanyBlue: "#00B2A9",
  allPorts: "#0076A8",
  indigo: "#486CC7",
  toolbox: "#7474C1",
  purpleMountainsMajesty: "#A57FB2",
  freeSpeechMagenta: "#E45DBF",
  vividCerise: "#DF1995",
  shiraz: "#BA0C2F",
  flamingo: "#E5554F",
  mangoTango: "#EA7600",
  sunglow: "#FFC845",
  oracle: "#395056",
  athensGray: "#EBF0F3",
  white: "#ffffff",
  whiteSmoke: "#f5f5f5",
  textPrimary: "#002936",
} as const

const gray = {
  50: "#F9FAFB",
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
} as const

// add custom colors to the global theme types
declare module "@mui/material/styles/createPalette" {
  // eslint-disable-next-line no-unused-vars
  interface Palette {
    aoeu: typeof aoeuPalette
    gray: typeof gray
  }
  // eslint-disable-next-line no-unused-vars
  interface PaletteOptions {
    aoeu: typeof aoeuPalette
    gray: typeof gray
  }
}

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface,no-unused-vars
  interface DefaultTheme extends Theme {}
}

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface,no-unused-vars
  interface DefaultTheme extends Theme {}
}

type ColorShades = {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

type DesignSystemTheme = {
  /**
   * Typography tokens
   */
  type: {
    size: {
      "3xs": string
      "2xs": string
      "xs": string
      "sm": string
      "md": string
      "lg": string
      "xl": string
      "2xl": string
      "3xl": string
      "4xl": string
    }
    /**
     * Typography / Letter Spacing
     */
    letterSpacing: {
      tight: string
      tighter: string
      normal: string
      loose: string
      looser: string
      widest: string
    }
    /**
     * Typography / Line Height
     */
    lineHeight: {
      "xxs": string
      "xs": string
      "sm": string
      "md": string
      "lg": string
      "xl": string
      "2xl": string
      "3xl": string
      "4xl": string
      "5xl": string
    }
    /**
     * Typography / Weight
     */
    weight: {
      regular: number
      semibold: number
      bold: number
      extraBold: number
    }
    /**
     * Typography / Fonts
     */
    fonts: {
      sans: string
    }
  }
  borderRadius: {
    sm: string
    md: string
    lg: string
    xl: string
  }
  space: {
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
    800: string
    900: string
    1000: string
    1100: string
    1200: string
    1300: string
    1400: string
  }
  colors: {
    primary: {
      blue: ColorShades
      lightBlue: ColorShades
      white: string
      black: string
      text: string
      mysticHighlight: string
    }
    secondary: {
      red: ColorShades
      orange: ColorShades
      yellow: ColorShades
      lightGreen: ColorShades
      darkGreen: ColorShades
      lightPurple: ColorShades
      mediumPurple: ColorShades
      magenta: ColorShades
    }
    neutral: { 50: string } & ColorShades
    semantic: {
      success: {
        primary: string
        light: string
      }
      danger: {
        primary: string
        light: string
      }
      warning: {
        primary: string
        light: string
      }
    }
  }
}

declare module "@mui/material/styles" {
  // eslint-disable-next-line no-unused-vars
  interface BreakpointOverrides {
    xs: true // removes the `xs` breakpoint,
    xss: true
    s: true
    sm: true
    m: true
    ml: true
    l: true
    xl: true
    md: false
    lg: false
  }

  interface Theme {
    sizes: {
      contentWidth: string
    }
    /**
     * Design System Namespace
     */
    ds: DesignSystemTheme
  }

  // allow configuration using `createTheme()`
  interface ThemeOptions {
    sizes?: {
      contentWidth?: string
    }
    /**
     * Design System Namespace
     */
    ds?: DesignSystemTheme
  }
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
const baseTheme = createTheme({
  palette: {
    aoeu: aoeuPalette,
    gray,
    primary: {
      main: aoeuPalette.darkBlue,
      contrastText: aoeuPalette.white,
    },
    secondary: {
      main: aoeuPalette.appleGreen,
    },
    error: {
      main: aoeuPalette.shiraz,
      contrastText: aoeuPalette.white,
    },
    warning: {
      main: aoeuPalette.sunglow,
    },
    info: {
      main: aoeuPalette.allPorts,
    },
    success: {
      main: aoeuPalette.observatory,
    },
    text: {
      primary: aoeuPalette.darkGray,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      `"proxima-nova"`,
      `"Helvetica Neue"`,
      `"Helvetica"`,
      `"sans-serif"`,
    ].join(","),
  },
  breakpoints: {
    unit: "rem",
    step: 0.5,
    values: {
      xs: 20 /* 320px ( / 16 ) */,
      xss: 26.25 /* 420px ( / 16 ) */,
      s: 32 /* 512px ( / 16 ) */,
      sm: 40 /* 640px ( / 16 ) */,
      m: 48 /* 768px ( / 16 ) */,
      ml: 60 /* 960px ( / 16 ) */,
      l: 64 /* 1024px ( / 16 ) */,
      xl: 80 /* 1280px ( / 16 ) */,
    },
  },
  spacing: (factor: number) => `${0.5 * factor}rem`,
  sizes: {
    contentWidth: "min(1440px - 1.3rem * 2, 100% - 3rem)",
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "1rem 1rem 0 1rem",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "1rem",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          padding: "1rem",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: "1px",
          marginRight: "1px",
        },
      },
    },
  },
})

const extendedTheme = {
  ...baseTheme,
  ds: {
    type: {
      size: {
        "3xs": tokens.TypeSize3Xs,
        "2xs": tokens.TypeSize2Xs,
        "xs": tokens.TypeSizeXs,
        "sm": tokens.TypeSizeSm,
        "md": tokens.TypeSizeMd,
        "lg": tokens.TypeSizeLg,
        "xl": tokens.TypeSizeXl,
        "2xl": tokens.TypeSize2Xl,
        "3xl": tokens.TypeSize3Xl,
        "4xl": tokens.TypeSize4Xl,
      },
      letterSpacing: {
        tight: tokens.TypeLetterSpacingTight,
        tighter: tokens.TypeLetterSpacingTighter,
        normal: tokens.TypeLetterSpacingNormal,
        loose: tokens.TypeLetterSpacingLoose,
        looser: tokens.TypeLetterSpacingLooser,
        widest: tokens.TypeLetterSpacingWidest,
      },
      lineHeight: {
        "xxs": tokens.TypeLineHeightXxs,
        "xs": tokens.TypeLineHeightXs,
        "sm": tokens.TypeLineHeightSm,
        "md": tokens.TypeLineHeightMd,
        "lg": tokens.TypeLineHeightLg,
        "xl": tokens.TypeLineHeightXl,
        "2xl": tokens.TypeLineHeight2Xl,
        "3xl": tokens.TypeLineHeight3Xl,
        "4xl": tokens.TypeLineHeight4Xl,
        "5xl": tokens.TypeLineHeight5Xl,
      },
      weight: {
        regular: tokens.TypeWeightRegular,
        semibold: tokens.TypeWeightSemibold,
        bold: tokens.TypeWeightBold,
        extraBold: tokens.TypeWeightExtraBold,
      },
      fonts: {
        sans: tokens.TypeFontsSans,
      },
    },
    borderRadius: {
      sm: tokens.BorderRadiusSm,
      md: tokens.BorderRadiusMd,
      lg: tokens.BorderRadiusLg,
      xl: tokens.BorderRadiusXl,
    },
    space: {
      100: tokens.Space100,
      200: tokens.Space200,
      300: tokens.Space300,
      400: tokens.Space400,
      500: tokens.Space500,
      600: tokens.Space600,
      700: tokens.Space700,
      800: tokens.Space800,
      900: tokens.Space900,
      1000: tokens.Space1000,
      1100: tokens.Space1100,
      1200: tokens.Space1200,
      1300: tokens.Space1300,
      1400: tokens.Space1400,
    },
    colors: {
      primary: {
        blue: {
          100: tokens.ColorsPrimaryBlue100,
          200: tokens.ColorsPrimaryBlue200,
          300: tokens.ColorsPrimaryBlue300,
          400: tokens.ColorsPrimaryBlue400,
          500: tokens.ColorsPrimaryBlue500,
          600: tokens.ColorsPrimaryBlue600,
          700: tokens.ColorsPrimaryBlue700,
          800: tokens.ColorsPrimaryBlue800,
          900: tokens.ColorsPrimaryBlue900,
        },
        lightBlue: {
          100: tokens.ColorsPrimaryLightBlue100,
          200: tokens.ColorsPrimaryLightBlue200,
          300: tokens.ColorsPrimaryLightBlue300,
          400: tokens.ColorsPrimaryLightBlue400,
          500: tokens.ColorsPrimaryLightBlue500,
          600: tokens.ColorsPrimaryLightBlue600,
          700: tokens.ColorsPrimaryLightBlue700,
          800: tokens.ColorsPrimaryLightBlue800,
          900: tokens.ColorsPrimaryLightBlue900,
        },
        white: tokens.ColorsPrimaryWhite,
        black: tokens.ColorsPrimaryBlack,
        text: tokens.ColorsPrimaryText,
        mysticHighlight: tokens.ColorsPrimaryMysticHighlight,
      },
      secondary: {
        red: {
          100: tokens.ColorsSecondaryRed100,
          200: tokens.ColorsSecondaryRed200,
          300: tokens.ColorsSecondaryRed300,
          400: tokens.ColorsSecondaryRed400,
          500: tokens.ColorsSecondaryRed500,
          600: tokens.ColorsSecondaryRed600,
          700: tokens.ColorsSecondaryRed700,
          800: tokens.ColorsSecondaryRed800,
          900: tokens.ColorsSecondaryRed900,
        },
        orange: {
          100: tokens.ColorsSecondaryOrange100,
          200: tokens.ColorsSecondaryOrange200,
          300: tokens.ColorsSecondaryOrange300,
          400: tokens.ColorsSecondaryOrange400,
          500: tokens.ColorsSecondaryOrange500,
          600: tokens.ColorsSecondaryOrange600,
          700: tokens.ColorsSecondaryOrange700,
          800: tokens.ColorsSecondaryOrange800,
          900: tokens.ColorsSecondaryOrange900,
        },
        yellow: {
          100: tokens.ColorsSecondaryYellow100,
          200: tokens.ColorsSecondaryYellow200,
          300: tokens.ColorsSecondaryYellow300,
          400: tokens.ColorsSecondaryYellow400,
          500: tokens.ColorsSecondaryYellow500,
          600: tokens.ColorsSecondaryYellow600,
          700: tokens.ColorsSecondaryYellow700,
          800: tokens.ColorsSecondaryYellow800,
          900: tokens.ColorsSecondaryYellow900,
        },
        lightGreen: {
          100: tokens.ColorsSecondaryLightGreen100,
          200: tokens.ColorsSecondaryLightGreen200,
          300: tokens.ColorsSecondaryLightGreen300,
          400: tokens.ColorsSecondaryLightGreen400,
          500: tokens.ColorsSecondaryLightGreen500,
          600: tokens.ColorsSecondaryLightGreen600,
          700: tokens.ColorsSecondaryLightGreen700,
          800: tokens.ColorsSecondaryLightGreen800,
          900: tokens.ColorsSecondaryLightGreen900,
        },
        darkGreen: {
          100: tokens.ColorsSecondaryDarkGreen100,
          200: tokens.ColorsSecondaryDarkGreen200,
          300: tokens.ColorsSecondaryDarkGreen300,
          400: tokens.ColorsSecondaryDarkGreen400,
          500: tokens.ColorsSecondaryDarkGreen500,
          600: tokens.ColorsSecondaryDarkGreen600,
          700: tokens.ColorsSecondaryDarkGreen700,
          800: tokens.ColorsSecondaryDarkGreen800,
          900: tokens.ColorsSecondaryDarkGreen900,
        },
      },
      neutral: {
        50: tokens.ColorsNeutral50,
        100: tokens.ColorsNeutral100,
        200: tokens.ColorsNeutral200,
        300: tokens.ColorsNeutral300,
        400: tokens.ColorsNeutral400,
        500: tokens.ColorsNeutral500,
        600: tokens.ColorsNeutral600,
        700: tokens.ColorsNeutral700,
        800: tokens.ColorsNeutral800,
        900: tokens.ColorsNeutral900,
      },
      semantic: {
        success: {
          primary: tokens.ColorsSemanticSuccessPrimary,
          light: tokens.ColorsSemanticSuccessLight,
        },
        danger: {
          primary: tokens.ColorsSemanticDangerPrimary,
          light: tokens.ColorsSemanticDangerLight,
        },
        warning: {
          primary: tokens.ColorsSemanticWarningPrimary,
          light: tokens.ColorsSemanticWarningLight,
        },
      },
    },
  },
}

export { extendedTheme as theme }
