export const CURRENCY = {
  USD: "USD",
} as const

export const FEATURES = {
  LOGIN: "@perm:login",
  REGISTRATION: "@perm:registration",
  MAINTENANCE_MODE: "@perm:maintenance-mode",
  MARKETPLACE: "marketplace",
  DISCOUNTS: "marketplace:discounts",
  MARKETPLACE_TAXES_EVENTS: "marketplace:taxes:events",
  MARKETPLACE_TAXES_SUBSCRIPTIONS: "marketplace:taxes:subscriptions",
  FLEX_AI_EXPERIMENT: "flex-ai-experiment",
  ENABLE_FLEX_CURRICULUMS: "enable:flex-curriculums",
  ENABLE_NEW_ADD_TO_MY_CLASSES_FLOW: "enable:new-add-to-my-classes-flow",
  ENABLE_AUTO_DELETE_UNITS_ON_CLASS_DELETE:
    "enable:auto-delete-units-on-class-delete",
  ENABLE_NEW_SHARE_CLASS_MODAL_WITH_COPY_FEEDBACK:
    "enable:new-share-class-modal-with-copy-feedback",
  ENABLE_IMPROVED_COPY_CLASS_FLOW: "enable:improved-copy-class-flow",
  ENABLE_PRO_FEATURED_CONTENT: "enable:pro-featured-content",
  ENABLE_PRO_PACK_PROGRESS: "enable:pro-pack-progress",
  ENABLE_PRO_LEGACY_REDIRECT: "enable-pro-legacy-redirect",
} as const

// This list is not comprehensive and just has to be filled in as we go for now
export const ROLES = {
  ADMIN_PORTAL_ACCESS: "AOEU Admin Portal Access",
  WP_KINDA_ADMIN: "AOEU Admin",
  WP_FINANCE: "Finance",
  ACCOUNTANT: "Shop Accountant",
  EDITOR: "Editor",
  API_CONSUMER: "REST API Consumer",
  TEAM_MEMBER: "Team Member",
  SUBSCRIBER: "Subscriber",
  MARKETING: "Marketing",
  INSTRUCTOR: "Instructor",
  EDD_SUBSCRIBER: "EDD Subscriber",
  AUTHOR: "Author",
  ADVISOR: "Advisor",
  FLEX_SANDBOX_ACCOUNT: "FLEX Sandbox Bucket Account",
  CONTRIBUTOR: "Contributor",
  LEARNING_TEAM: "Learning Team",
  TEACHER: "Teacher",
  CUSTOMER_ENGAGEMENT: "Customer Engagement",
  ADMINISTRATOR: "Administrator",
  ACADEMIC_TEAM: "Academic Team",
} as const

export const STUDENT_TYPE = {
  NONE: "N/A",
  MASTERS: "Masters",
  COURSE_ONLY: "Course Only",
} as const

export const ORGANIZATION_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
} as const

export const PRODUCT_NAMES = {
  FLEX: "FLEX Curriculum",
  PRO: "PRO Learning",
  CURRICULUM_SUITE: "Curriculum Suite",
  NOW_CONFERENCE: "NOW Conference",
} as const

export const PRODUCT_ENTITLEMENT_STATUS = {
  ACTIVATED: "Activated",
  PENDING: "Pending",
  REVOKED: "Revoked",
  EXPIRED: "Expired",
  CANCELED: "Canceled",
  UNPAID: "Unpaid",
} as const

export const FLEX_REFERENCE_TYPE = {
  AREA_OF_STUDY: "areaOfStudy",
  COLLECTION: "collection",
  PRO_PACK: "proPack",
  LEVEL: "level",
  GRADE_LEVEL: "gradeLevel",
  STANDARD: "standard",
} as const

export const FLEX_ASSET_TYPE = {
  LESSON_PLAN: "lessonPlan",
  RESOURCE: "resource",
  VIDEO: "video",
  ASSESSMENT: "assessment",
} as const

export const PRO_PACK_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETE: "COMPLETE",
  NOT_STARTED: "NOT_STARTED",
} as const

const MILLISECOND = 1
const SECOND = 1000 * MILLISECOND
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
export const TIME = {
  QUARTER_SECOND: MILLISECOND * 250,
  HALF_SECOND: MILLISECOND * 500,
  ONE_SECOND: SECOND,
  TWO_POINT_FIVE_SECONDS: MILLISECOND * 2500,
  FIVE_SECONDS: SECOND * 5,
  TEN_SECONDS: SECOND * 10,
  TWENTY_SECONDS: SECOND * 20,
  ONE_MINUTE: MINUTE,
  TWO_MINUTES: MINUTE * 2,
  THREE_MINUTES: MINUTE * 3,
  FOUR_MINUTES: MINUTE * 4,
  FIVE_MINUTES: MINUTE * 5,
  TEN_MINUTES: MINUTE * 10,
  TWENTY_MINUTES: MINUTE * 20,
  THIRTY_MINUTES: MINUTE * 30,
  FORTY_MINUTES: MINUTE * 40,
  FIFTY_MINUTES: MINUTE * 50,
  ONE_HOUR: HOUR,
} as const

export const GOLANG_EMPTY_UUID = "00000000-0000-0000-0000-000000000000" as const

export const SUPPORT_EMAIL_ADDRESS = "help@theartofeducation.edu" as const
