import { styled } from "@mui/material/styles"
import { MaterialDesignContent } from "notistack"

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent": {
      fontSize: "1rem",
    },
    "&.notistack-MuiContent-success": {
      backgroundColor: theme.ds.colors.semantic.success.primary,
    },
  }),
)

export const StyledMaterialDesignContentDefaultVariant = styled(
  MaterialDesignContent,
)(() => ({
  "&.notistack-MuiContent": {
    backgroundColor: "#545555", // neutral-600,
    borderRadius: "8px",
    // apply Body SM typography
    fontWeight: 400,
    fontSize: "1rem",
    minWidth: "auto",
  },
  "&.notistack-MuiContent-success": {
    backgroundColor: "#38853C",
  },
}))
